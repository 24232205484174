<template>
    <div class="filter" >
        <h2>Fai un preventivo per l’assicurazione di viaggio</h2>

        <div class="filter-row">
            <!-- <label class="d-flex">Data prenotazione viaggio <IconInfo v-if="hintDataPrenotazione" :title="'asd'" :testoPopup="'asdsadasd'" style="margin-left: 5px"></IconInfo></label>
            <div>
                <input type="date" v-model="dataPrenotazioneModel" :min="today" />
            </div> -->
            <!-- 
            <label class="d-flex">Destinazione <IconInfo v-if="hintDestinazione" :title="'title'" :testoPopup="'testo'" style="margin-left: 5px;margin-top:-10px; width:15px"></IconInfo></label>

            <div>
                <select v-model="destinazioneStato">
                    <option v-for="destinazione in destinazioniPreventivatore" :key="destinazione.value" :value="destinazione.value">{{ destinazione.text }}</option>
                </select>
            </div>  -->
        </div>

        <!-- <div :class="usaFasce ? 'webins-preventivatore-row' : ''"> -->
        <div class="webins-preventivatore-row">
            <div style="margin: 10px">
                <CustomInput
                    :placeHolder="'Destinazione'"
                    :hint="'In caso di viaggio con più destinazioni, indicare il Paese più distante. Fermo restando i massimali previsti per ciascuna destinazione. Quando una delle tappe è USA o Canada, indicare sempre una di queste due destinazioni.'"
                    :type="'select'"
                    :optionLabel="'Seleziona un\'opzione'"
                    :dataSource="destinazioniPreventivatore"
                    v-model:value="destinazioneStato"
                ></CustomInput>
            </div>

            <div style="margin: 10px">
                <CustomInput :placeHolder="'Data inizio viaggio'" :type="'date'" v-model:value="inizioViaggioModel" :minDate="today"></CustomInput>
            </div>

            <div style="margin: 10px">
                <CustomInput :placeHolder="'Data fine viaggio'" :type="'date'" v-model:value="fineViaggioModel" :minDate="fineViaggioMinimo"></CustomInput>
            </div>

            <div style="margin: 10px">
                <CustomInput :placeHolder="'Codice Promozionale'" :type="'string'" v-model:value="codiceCoupon"></CustomInput>
            </div>
        </div>

        <!-- <div class="filter-row" v-if="false">
            <label>Data Inizio Viaggio</label>
            <div>
                <input type="date" v-model="inizioViaggioModel" :min="today" />
            </div>
            <label>Data Fine Viaggio</label>
            <div>
                <input type="date" v-model="fineViaggioModel" :min="fineViaggioMinimo" />
            </div>
        </div> -->

        <template v-if="usaFasce">
            <div style="margin-left: 20px; margin-top: 15px; font-size: 20px">
                <span class="mb-1">Viaggiatori per fascia d'età</span>
            </div>

            <div class="webins-preventivatore-row" style="margin-top: 15px">
                <div v-for="(fascia, i) in elencoFasceEta" :key="i" style="margin: 10px">
                    <CustomInput :placeHolder="fascia.descrizione" :type="'number'" v-model:value="numeroPartecipantiPerFascia[i].numeroPartecipanti"></CustomInput>
                </div>
            </div>

            <div  v-if="usaImportoViaggio" class="webins-preventivatore-row" style="margin-top: 15px">
                <div style="margin: 10px">
                    <CustomInput :placeHolder="'Importo Viaggio ( € )'" :type="'number'" v-model:value="importoViaggio"></CustomInput>
                </div>
            </div>
            
        </template>
        <template v-else>
            <!-- <div :class="usaFasce ? 'webins-preventivatore-row' : ''" style="margin-top: 15px"> -->
            <div class="webins-preventivatore-row" style="margin-top: 15px">
                <div style="margin: 10px">
                    <CustomInput :placeHolder="'Numero Partecipanti'" :type="'number'" v-model:value="numeroPartecipanti"></CustomInput>
                </div>
                <div v-if="usaImportoViaggio" style="margin: 10px">
                    <CustomInput :placeHolder="'Importo Viaggio ( € )'" :type="'number'" v-model:value="importoViaggio"></CustomInput>
                </div>
            </div>
        </template>

        <div class="center" style="margin-top: 15px">
            <button class="btn" @click="search" :disabled="searchDisabled">{{ t("step0.btn") }}</button>
        </div>

        <div class="center cnt-widget-text-annullamento" style="margin-top: 15px">
            <span class="widget-text-annullamento" >Attenzione: la polizza con la garanzia annullamento può essere acquistata entro 24 ore dalla prenotazione del primo servizio turistico.</span>
        </div>

        <Popup ref="popupAlert">
            <div style="font-weight: bold" v-html="alertText"></div>
        </Popup>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { useWidget, useNavigation, usePolizze } from "@/composables";
import { ParametriPreventivatoreFasceEta, ParametriPreventivatoreItem } from "@/models/ParametriPreventivatore";
import CustomInput from "@/components/custom-input.vue";
import Popup from "@/components/popup.vue";

const { t } = useI18n();
const { destinazioniPreventivatore, elencoEta, elencoFasceEta, datiIniziali, widgetConfig, decodificaStato } = useWidget();
const { getPreventivatore, loading, getParametriSchedaPreventivatore, parametriPreventivatore } = usePolizze();
const { next } = useNavigation();

const today = ref(dayjs().toDate());
const fineViaggioMinimo = ref(dayjs().toDate());
const dataPrenotazione = ref(dayjs());
const inizioViaggio = ref(dayjs());
const fineViaggio = ref(dayjs());
const destinazioneStato = ref();
const etaModel = ref(0);
const codiceCoupon = ref("");
const numeroPartecipanti = ref<number>();
const importoViaggio = ref<number>();

const popupAlert = ref(null);
const alertText = ref("");
const usaFasce = ref<boolean>(false);
const usaImportoViaggio = ref<boolean>(false);

const numeroPartecipantiPerFascia = ref<ParametriPreventivatoreFasceEta[]>([]);

const hintDataPrenotazione = ref<boolean>(false);
const hintDestinazione = ref<boolean>(false);

for (var i = 0; i < elencoFasceEta.value.length; i++)
    numeroPartecipantiPerFascia.value.push({ idFascia: elencoFasceEta.value[i].itemID, descrizione: elencoFasceEta.value[i].descrizione, numeroPartecipanti: null });

onMounted(() => {
    if(widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello)
        return;

    //dataPrenotazione.value = dayjs(today.value);
    usaFasce.value = widgetConfig.value && widgetConfig.value.usaFasceEta ? true : false;
    usaImportoViaggio.value = widgetConfig.value && widgetConfig.value.usaImportoViaggio ? true : false;

    if (parametriPreventivatore && parametriPreventivatore.value) {
        parametriPreventivatore.value.dataPrenotazione && (dataPrenotazione.value = dayjs(parametriPreventivatore.value.dataPrenotazione));
        parametriPreventivatore.value.destinazioneStato && (destinazioneStato.value = parametriPreventivatore.value.destinazioneStato);
        parametriPreventivatore.value.inizioViaggio && (inizioViaggio.value = dayjs(parametriPreventivatore.value.inizioViaggio));
        parametriPreventivatore.value.fineViaggio && (fineViaggio.value = dayjs(parametriPreventivatore.value.fineViaggio));
        parametriPreventivatore.value.numeroPartecipanti && (numeroPartecipanti.value = parametriPreventivatore.value.numeroPartecipanti);
        parametriPreventivatore.value.eta && (etaModel.value = parametriPreventivatore.value.eta);
        parametriPreventivatore.value.codiceCoupon && (codiceCoupon.value = parametriPreventivatore.value.codiceCoupon);

        if(usaImportoViaggio.value){
            parametriPreventivatore.value.importoViaggio && (importoViaggio.value = parametriPreventivatore.value.importoViaggio);
        }

        if (parametriPreventivatore.value.fasceEta && parametriPreventivatore.value.fasceEta.length) {
            for (var i = 0; i < parametriPreventivatore.value.fasceEta.length; i++) {
                var f = numeroPartecipantiPerFascia.value.find((obj) => obj.idFascia === parametriPreventivatore.value.fasceEta[i].idFascia);
                f && parametriPreventivatore.value.fasceEta[i].numeroPartecipanti && (f.numeroPartecipanti = parametriPreventivatore.value.fasceEta[i].numeroPartecipanti);
            }
        }
    }
    if (datiIniziali && datiIniziali.value) {
        !parametriPreventivatore.value.dataPrenotazione && datiIniziali.value.dataPrenotazione && (dataPrenotazione.value = dayjs(datiIniziali.value.dataPrenotazione));
        !destinazioneStato.value && datiIniziali.value.destinazione && (destinazioneStato.value = decodificaStato(datiIniziali.value.destinazione));
        !parametriPreventivatore.value.inizioViaggio && datiIniziali.value.inizioViaggio && (inizioViaggio.value = dayjs(datiIniziali.value.inizioViaggio));
        !parametriPreventivatore.value.fineViaggio && datiIniziali.value.fineViaggio && (fineViaggio.value = dayjs(datiIniziali.value.fineViaggio));
        !numeroPartecipanti.value && datiIniziali.value.numeroPartecipanti && (numeroPartecipanti.value = datiIniziali.value.numeroPartecipanti);
        //!etaModel.value && datiIniziali.value.fasciaEta && (etaModel.value = elencoEta.value[datiIniziali.value.fasciaEta - 1].value);
        !parametriPreventivatore.value.codiceCoupon && datiIniziali.value.codiceCoupon && (codiceCoupon.value = datiIniziali.value.codiceCoupon);

        if(usaImportoViaggio.value){
            !importoViaggio.value && datiIniziali.value.importoViaggio && (importoViaggio.value = datiIniziali.value.importoViaggio);
        }

        if (!parametriPreventivatore.value.fasceEta || !parametriPreventivatore.value.fasceEta.length) {
            if (datiIniziali.value.fasceEta && datiIniziali.value.fasceEta.length) {
                for (var i = 0; i < datiIniziali.value.fasceEta.length; i++) {
                    var f = numeroPartecipantiPerFascia.value.find((obj) => obj.idFascia === datiIniziali.value.fasceEta[i].idFascia);
                    f && datiIniziali.value.fasceEta[i].numeroPartecipanti && (f.numeroPartecipanti = datiIniziali.value.fasceEta[i].numeroPartecipanti);
                }
            }
        }
    }
});

const searchDisabled = computed(() => false);

const dataPrenotazioneModel = computed({
    get(): string {
        return dataPrenotazione.value.format("YYYY-MM-DD");
    },
    set(newValue: string): void {
        dataPrenotazione.value = dayjs(newValue);
    }
});

const inizioViaggioModel = computed({
    get(): Date {
        //return inizioViaggio.value.format("YYYY-MM-DD");
        return inizioViaggio.value ? inizioViaggio.value.toDate() : null;
    },
    set(newValue: Date): void {
        inizioViaggio.value = dayjs(newValue);

        if (inizioViaggio.value && inizioViaggio.value.isValid()) {
            //fineViaggioMinimo.value = inizioViaggio.value.format("YYYY-MM-DD");
            fineViaggioMinimo.value = inizioViaggio.value.toDate();

            if (inizioViaggio.value > fineViaggio.value) {
                fineViaggio.value = inizioViaggio.value;
            }
        } else {
            fineViaggio.value = dayjs();
            //fineViaggioMinimo.value = dayjs().format("YYYY-MM-DD");
            fineViaggioMinimo.value = dayjs().toDate();
        }
    }
});

const fineViaggioModel = computed({
    get(): Date {
        return fineViaggio.value.toDate();
    },
    set(newValue: Date): void {
        fineViaggio.value = dayjs(newValue);
    }
});

const search = async () => {
    var nPart = numeroPartecipanti.value;
    if (!nPart || isNaN((nPart = parseInt(String(nPart))))) nPart = null;

    let parametri: ParametriPreventivatoreItem = {
        dataPrenotazione: dataPrenotazione.value && dataPrenotazione.value.isValid() ? dataPrenotazione.value.toDate() : null,
        inizioViaggio: inizioViaggio.value && inizioViaggio.value.isValid() ? inizioViaggio.value.toDate() : null,
        fineViaggio: fineViaggio.value && fineViaggio.value.isValid() ? fineViaggio.value.toDate() : null,
        destinazioneStato: destinazioneStato.value ? parseInt(destinazioneStato.value) : null,
        eta: etaModel.value,
        numeroPartecipanti: nPart && nPart > 0 ? nPart : null,
        fasceEta: [],
        codiceCoupon: codiceCoupon.value || null,
        importoViaggio: usaImportoViaggio.value ? importoViaggio.value || 0 : 0,
        prodottiSelezionati: []
    };

    if (usaFasce.value) {
        var totPartSpecificati = 0;
        for (var i = 0; i < numeroPartecipantiPerFascia.value.length; i++) {
            var n = parseInt(String(numeroPartecipantiPerFascia.value[i].numeroPartecipanti));
            if (!n || isNaN(n) || n < 0) numeroPartecipantiPerFascia.value[i].numeroPartecipanti = null;
            else numeroPartecipantiPerFascia.value[i].numeroPartecipanti = n;

            parametri.fasceEta.push(JSON.parse(JSON.stringify(numeroPartecipantiPerFascia.value[i])));
            totPartSpecificati += numeroPartecipantiPerFascia.value[i].numeroPartecipanti || 0;
        }

        if (!totPartSpecificati) {
            alertText.value = "Impostare almeno un partecipante";
            popupAlert.value.show();
            return;
        }

        parametri.numeroPartecipanti = totPartSpecificati;
    }
    else{

        if (!parametri.numeroPartecipanti || parametri.numeroPartecipanti < 0) {
            alertText.value = "Impostare almeno un partecipante";
            popupAlert.value.show();
            return;
        }
        parametri.fasceEta = [ { idFascia: 0, numeroPartecipanti: parametri.numeroPartecipanti, descrizione: ""}];
    }


    if (!parametri.inizioViaggio || !parametri.fineViaggio || !parametri.destinazioneStato) {
        alertText.value = "Inserire tutti i parametri per avviare la ricerca";
        popupAlert.value.show();
        return;
    }

    await getPreventivatore(parametri);

    await getParametriSchedaPreventivatore();

    next();
};
</script>
