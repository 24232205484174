<template>
    <div class="webins-widget">
        <Navigation v-if="currentStep > 0 && currentStep < 5" />
        <!-- <AreaSelection v-if="currentStep==0" /> -->
        <Preventivatore v-if="currentStep == 0" />
        <div v-else class="webins-widget-step-container">
            <RisultatiPreventivatore v-if="currentStep == 1" />
            <DatiPartecipanti v-else-if="currentStep == 2" />
            <Payment v-else-if="currentStep == 3" />
            <Confirmation v-else-if="currentStep == 4" />
        </div>
        
        <Popup ref="popupAlert" :disable-close="true">
            <div style="font-weight: bold" v-html="alertText"></div>
        </Popup>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useWidget, useNavigation, useReservation, usePolizze } from "@/composables";
import Navigation from "@/components/navigation.vue";
// import AreaSelection from "@/components/step0/step0-AreaSelection.vue";
import Preventivatore from "@/components/webins/preventivatore/preventivatore.vue";
import RisultatiPreventivatore from "./components/webins/risultatiPreventivatore/risultati-preventivatore.vue";
import DatiPartecipanti from "./components/webins/datiDeiPartecipanti/dati-partecipanti.vue";
import Payment from "@/components/webins/pagamento/pagamento.vue";
import Confirmation from "@/components/webins/conferma/conferma.vue";
import dayjs from "dayjs";
import { ParametriPreventivatoreItem } from "./models/ParametriPreventivatore";
import Popup from "@/components/popup.vue";

const { isAppIdValid, appCode, widgetConfig, datiIniziali, decodificaStato, options } = useWidget();
const { goTo, currentStep } = useNavigation();
const { guidAdesioneSearch, getPreventivatore, getParametriSchedaPreventivatore } = usePolizze();
const alertText = ref("");
const popupAlert = ref(null);

onMounted(() => {
    let nextStep = 0;
    
    if (isAppIdValid) {
        console.log("App loaded with code: " + JSON.stringify(appCode.value));
    } else console.log("App global");

    if (window.location.search) {
        const paramsStr = window.location.search.substring(1); //Puts hash in variable, and removes the # character
        const params = paramsStr.split("&");
        for (let index = 0; index < params.length; index++) {
            const keyValuePair = params[index].split("=");
            if (keyValuePair.length == 2 && keyValuePair[0].toLowerCase() == "webinsid") {
                guidAdesioneSearch.value = keyValuePair[1];
                console.log("WebinsId: " + guidAdesioneSearch.value);
                nextStep = 4;
                goTo(nextStep);
                return;
            }
        }
    }

    if(widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello) {
        // richiama subito preventivatore e vai alla lista 
        if (!options.value.addToCartCallback) {
            alertText.value = "Impossibile caricare il widget. Non e' stata impostata una callback per gestire il carrello";
            popupAlert.value.show();
            return;
        }

        richiamaPreventivatore();
        return;
    }

    if (window.location.hash) {
        const hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
        console.log("Hash: " + hash);
    }

    goTo(nextStep);
});

const richiamaPreventivatore = async () =>{

    if (!datiIniziali || !datiIniziali.value)
        return;

    //console.log("Parametri in ingresso: " + JSON.stringify(datiIniziali.value));

    var dPrenotazione = datiIniziali.value.dataPrenotazione ? dayjs(datiIniziali.value.dataPrenotazione) : null,
        inizioViaggio = datiIniziali.value.inizioViaggio ? dayjs(datiIniziali.value.inizioViaggio) : dayjs(),
        fineViaggio = datiIniziali.value.fineViaggio ? dayjs(datiIniziali.value.fineViaggio) : dayjs(),
        destinazione = decodificaStato(datiIniziali.value.destinazione);

    


    let parametri: ParametriPreventivatoreItem = {
        dataPrenotazione: dPrenotazione && dPrenotazione.isValid() ? dPrenotazione.toDate() : null,
        inizioViaggio: inizioViaggio && inizioViaggio.isValid() ? inizioViaggio.toDate() : null,
        fineViaggio: fineViaggio && fineViaggio.isValid() ? fineViaggio.toDate() : null,
        destinazioneStato: destinazione || null,
        eta: null,
        numeroPartecipanti: null,
        fasceEta: [],
        codiceCoupon: datiIniziali.value.codiceCoupon || null,
        importoViaggio: widgetConfig.value.usaImportoViaggio && datiIniziali.value.importoViaggio ? parseFloat(datiIniziali.value.importoViaggio as any) : 0,
        prodottiSelezionati: []
    };

    //console.log("Parametri rielaborati: " + JSON.stringify(parametri));
    if(widgetConfig.value.usaFasceEta){
        if (datiIniziali.value.fasceEta && datiIniziali.value.fasceEta.length) {
            var totPartecipanti = 0;
            for (var i = 0; i < datiIniziali.value.fasceEta.length; i++) {
                var f = datiIniziali.value.fasceEta[i];
                if(f.numeroPartecipanti > 0 ){
                    totPartecipanti += f.numeroPartecipanti;
                    parametri.fasceEta.push({
                        idFascia: f.idFascia,
                        descrizione: "",
                        numeroPartecipanti: f.numeroPartecipanti
                    })
                }
            }

            parametri.numeroPartecipanti = totPartecipanti;
        }
    }
    else{
        parametri.numeroPartecipanti = datiIniziali.value.numeroPartecipanti;
        parametri.fasceEta = [ { idFascia: 0, numeroPartecipanti: parametri.numeroPartecipanti, descrizione: ""}];
    }
    
    
    //console.log("OK 1");

    if (!parametri.inizioViaggio || !parametri.fineViaggio || !parametri.destinazioneStato || !parametri.numeroPartecipanti) {
        alertText.value = "Impossibile caricare le polizze senza aver specificato tutti i parametri";
        popupAlert.value.show();
        return;
    }

    //console.log("OK 2");
    if(!datiIniziali.value.assicurati || datiIniziali.value.assicurati.length !== parametri.numeroPartecipanti ){
        alertText.value = "Non sono stati specificati i nominativi di tutti gli assicurati";
        popupAlert.value.show();
        return;
    }

    //console.log("OK 3");
    for(var i = 0; i < datiIniziali.value.assicurati.length; i++){
        var a = datiIniziali.value.assicurati[i];
        if(!a.nome || !a.cognome){
            alertText.value = "Non sono stati specificati i nominativi di tutti gli assicurati";
            popupAlert.value.show();
            return;
        }
    }

    //console.log("Richiamo preventivatore");
    await getPreventivatore(parametri);

    await getParametriSchedaPreventivatore();
    goTo(1);
}
</script>

<style lang="less">
@import "~themestyle.less";
</style>
