<template>
    <div style="position: relative">

        <template v-if="type === 'number'">
            <input ref="field" @input="handleInput" class="webins-custom-input" type="number" min="0" 
            :class="{ 'focus': hasFocus || value, 'webins-error-field': errore }"   @focus="onFocus" @blur="onBlur"
            :disabled="disabled"  :value="value" required  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
            <!-- <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'webins-arrow-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-opacity=".01" d="M0 0h48v48H0z"/><path d="M37 18L25 30 13 18" stroke="#6c757d" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg> -->
            <span class="webins-floating-label" :class="{ 'webins-error-field': errore }">{{ placeHolder }}</span>
        </template>

        <template v-else-if="type === 'select'">
            <select  ref="field" class="webins-custom-input" @change="handleSelectChange" :value="value" :disabled="disabled"
            :class="{ 'focus': hasFocus || value, 'webins-error-field': errore  }"   @focus="onFocus" @blur="onBlur"
            >
                <option value="" v-if="optionLabel">{{ optionLabel }}</option>
                <option v-for="item in dataSource" :key="item[ valueField ? valueField : 'value' ]" 
                :value="item[ valueField ? valueField : 'value' ]">{{ item.text }}</option>
            </select>
            <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'webins-arrow-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-opacity=".01" d="M0 0h48v48H0z"/><path d="M37 18L25 30 13 18" stroke="#6c757d" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <span class="webins-floating-label webins-fixed-label" :class="{ 'webins-error-field': errore  }">{{ placeHolder }}
                <IconInfo class="webins-input-info" v-if="hint" :title="hint" :testoPopup="hint"></IconInfo>
                
            </span>
        </template>

        <template v-else-if="type === 'date'">
            <VueDatePicker ref="datepicker"  v-model="dateModel" placeholder="GG/MM/AAAA"
            :disabled="disabled" :auto-position="true"
            :text-input="{ format: 'dd/MM/yyyy' }" :format="formatDate" locale="it"
            cancelText="Annulla" selectText="Seleziona" :enable-time-picker="false" @date-update="dateClicked" 
            model-type="dd/MM/yyyy" calendar-class-name="dp-webins-calendar"  menu-class-name="dp-custom-menu"
            @blur="onCloseDate" :teleport="true"
            :min-date="minDate" :max-date="maxDate"
            >
             <template #dp-input="{ value, onInput,  onKeypress, onEnter }">
                <input ref="inputDate" class="dp__input"  :class="{ 'webins-error-field': errore }" type="text" :value="value" @input="onInput" @keypress="onDatePickerKeyPress($event, onKeypress, onEnter)" 
                placeholder="GG/MM/AAAA" />
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'webins-calendar-icon'" @click="openDatePicker" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 4H8v.5a.5.5 0 01-1 0V4H5.5A1.5 1.5 0 004 5.5V8h16V5.5A1.5 1.5 0 0018.5 4H17v.5a.5.5 0 11-1 0V4zm1-1h1.5A2.5 2.5 0 0121 5.5v13.007a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V5.5A2.5 2.5 0 015.5 3H7v-.5a.5.5 0 011 0V3h8v-.5a.5.5 0 111 0V3zm3 6H4v9.507a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5V9z"/></svg>
                    <!-- <svg viewBox="0 0 16 16" width="48" height="48" aria-label="x" xmlns="http://www.w3.org/2000/svg" v-if="dateIsSelected" v-bind:svg-inline="''" v-bind:class="'webins-x-calendar-icon'" @click="clearDate" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.646 4.646a.5.5 0 01.708 0L8 7.293l2.646-2.647a.5.5 0 01.708.708L8.707 8l2.647 2.646a.5.5 0 01-.708.708L8 8.707l-2.646 2.647a.5.5 0 01-.708-.708L7.293 8 4.646 5.354a.5.5 0 010-.708z"/></svg> -->
                    
                </template> 
                <template #action-row></template>
            </VueDatePicker>
            <!-- <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'webins-calendar-icon'" @click="openDatePicker" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 4H8v.5a.5.5 0 01-1 0V4H5.5A1.5 1.5 0 004 5.5V8h16V5.5A1.5 1.5 0 0018.5 4H17v.5a.5.5 0 11-1 0V4zm1-1h1.5A2.5 2.5 0 0121 5.5v13.007a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V5.5A2.5 2.5 0 015.5 3H7v-.5a.5.5 0 011 0V3h8v-.5a.5.5 0 111 0V3zm3 6H4v9.507a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5V9z"/></svg>
            <svg viewBox="0 0 16 16" width="48" height="48" aria-label="x" xmlns="http://www.w3.org/2000/svg" v-if="dateIsSelected" v-bind:svg-inline="''" v-bind:class="'webins-x-calendar-icon'" @click="clearDate" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.646 4.646a.5.5 0 01.708 0L8 7.293l2.646-2.647a.5.5 0 01.708.708L8.707 8l2.647 2.646a.5.5 0 01-.708.708L8 8.707l-2.646 2.647a.5.5 0 01-.708-.708L7.293 8 4.646 5.354a.5.5 0 010-.708z"/></svg> -->
            <span class="webins-floating-label webins-fixed-label"  :class="{ 'webins-error-field': errore }">{{ placeHolder }}</span>
        </template>

        <template v-else>
            <input :class="{ 'focus': hasFocus || value, 'webins-error-field': errore  }" ref="field" @input="handleInput"  @change="handleInput" class="webins-custom-input" :type="email ? 'email' : 'text'" 
            @focus="onFocus" @blur="onBlur"
            :disabled="disabled" :value="value" required />
            <!-- <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'webins-arrow-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-opacity=".01" d="M0 0h48v48H0z"/><path d="M37 18L25 30 13 18" stroke="#6c757d" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg> -->
            <span class="webins-floating-label"  :class="{ 'webins-error-field': errore  }">{{ placeHolder }}</span>
        </template>
    </div>
</template>

<script setup lang="ts" >

import { TextValueItem } from "@/models/TextValueItem";
import { defineProps, defineEmits, ref, watch, computed, onMounted } from "vue";

import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from "@vuepic/vue-datepicker"
import '@vuepic/vue-datepicker/dist/main.css';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import IconInfo from "@/components/icon-info-positional.vue";
dayjs.extend(customParseFormat)

//const value = ref(null);
const field = ref(null);
const date = ref<string>(null);
const dateIsSelected = ref<boolean>(false);

const datepicker = ref<DatePickerInstance>(null);
const inputDate = ref(null);

const hasFocus = ref<boolean>(false);
const resetErrore = ref<boolean>(false);
const focusClass = ref<string>("");

const props =
    defineProps<{
        placeHolder?: string;
        testoPopup?: string;
        hint?: string;
        optionLabel?: string;
        dataSource?: TextValueItem[];
        valueField?: string
        email?: boolean;
        disabled?: boolean;
        type?: string;
        value?: string | number | Date;
        //date?: Date;
        min?: number | string | Date;
        max?: number | string | Date;
        minDate?: string | Date;
        maxDate?: string | Date;
        errore?: boolean;
    }>();



const emit = defineEmits(['update:value', 'onSelectDropDown', 'updateValue'])

onMounted(() =>{
    if(props.type === "date" && props.value){
        var d = dayjs(props.value);
        d && d.isValid() && ( date.value = d.format("DD/MM/YYYY") );
    }

})

watch(
    () => [date.value],
    () => {
        dateIsSelected.value = date.value ? true : false;
        //dateIsSelected.value = props.date ? true : false;
    },
    { deep: true }
);

watch(
    () => [props.errore],
    () => {
        //if(props.errore ){
            resetErrore.value = false;
        //}
    },
    { deep: true, immediate: true }
);



watch(
    () => [props.value],
    () => {
        if(props.type === "date"){
            date.value = props.value ? dayjs(props.value).format("DD/MM/YYYY") : null;
        }
        
        focusClass.value = hasFocus.value || props.value ? 'focus' : '';
    },
    { deep: true }
);

watch(
    () => [hasFocus.value],
    () => {
        focusClass.value = hasFocus.value || props.value ? 'focus' : '';
    },
    { deep: true }
);

const onDatePickerKeyPress = (event, onKeyPress, onEnter) => {
    onKeyPress(event);
    if(event.keyCode === 13)
        onEnter(event);
};

const onFocus = () => {
    hasFocus.value = true;
}

const onBlur = () => {
    hasFocus.value = false;
}

const handleInput = (e) => {
    emit("update:value", e.target.value );
    if(props.errore){
        resetErrore.value = true;
    }
}

const handleSelectChange = (e) => {
    
    var itemSelected = props.dataSource.find(x => String(x[props.valueField || 'value']) === e.target.value);
    emit("onSelectDropDown", itemSelected );
    emit("update:value", e.target.value );
    if(props.errore){
        resetErrore.value = true;
    }
}

const dateClicked = (date) => {
    setTimeout(() => {
        datepicker.value.selectDate();
    }, 100);
  }

const openDatePicker = (date) => {
    datepicker.value.openMenu();
}
const clearDate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    datepicker.value.clearValue();
    return false;
}


const onCloseDate = () => {    
    if(lockBlurDate){
        lockBlurDate = false;
        return
    }
    var dateText = inputDate.value.value;
    var d = dayjs(dateText, "DD/MM/YYYY");
    if( d && d.isValid() ){
        var minD = props.minDate ? dayjs(props.minDate) : null,
            maxD = props.maxDate ? dayjs(props.maxDate) : null;

        if( (!minD || (minD <= d ) && ( !maxD || (d <= maxD) ) )  ){
            var newD = d.format("DD/MM/YYYY");
            dateModel.value = newD;
            //date.value = newD;
            //lockBlurDate = true;
            //datepicker.value.selectDate();
        }
    }
}



const textInputOptions = {
  openMenu: true,
  format: 'dd/MM/yyyy'
};

const formatDate = (date) => {
  var d = dayjs(date);
  if( d && d.isValid() )
    return d.format("DD/MM/YYYY");
  return date;
};

var lockBlurDate = false;

const dateModel= computed({
    get(): string {
        return date.value || null;
    },
    set(newValue: string): void {
        date.value = newValue;
        var d = dayjs(newValue, "DD/MM/YYYY");
        
        emit("update:value",  d && d.isValid() ? d.toDate() : null );
        emit("updateValue",  d && d.isValid() ? d.toDate() : null );
        if(props.errore){
            resetErrore.value = true;
        }
        if(d.isValid()){
            lockBlurDate = true;
            setTimeout(() => {
                lockBlurDate = false;
            }, 200);

        }
    }
});

</script>

<style scoped>
/*
input:focus ~ .webins-floating-label,
.webins-floating-label.webins-fixed-label,
input:not(:focus):valid ~ .webins-floating-label {
    top: -10px;
    left: 8px;
    font-size: 15px;
    opacity: 1;
}*/

input.focus ~ .webins-floating-label,
.webins-floating-label.webins-fixed-label {
    top: -10px;
    left: 8px;
    font-size: 15px;
    opacity: 1;
}

.webins-custom-input {
    /* font-size: 14px;
  width: 200px;
  height: 35px;*/
  font-size: 18px;
}

input.webins-custom-input {
  padding: 15px;
  /* padding-right: 30px; */
}
select.webins-custom-input {
  padding: 10px;
  padding-right: 30px;
}

.webins-floating-label {
    padding-left: 5px;
    padding-right: 10px;
    font-size: 18px;
    background-color: white;
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 14px;
    transition: 0.2s ease all;
}


.webins-arrow-icon {
    position: absolute;
    top: 9px;
    margin-left: -32px;
    float: right;
    width: 30px;
    pointer-events: none;
    z-index: 1;
}

.webins-calendar-icon {
   /* position: absolute;
    top: 9px;
    right: 5px;
    width: 30px;
    cursor: pointer;
    z-index: 1;
    */

    position: absolute;
    top: 9px;
    margin-left: -32px;
    float: right;
    width: 30px;
    cursor: pointer;
    z-index: 1;
    fill: #797979;
}

.webins-x-calendar-icon {
    position: absolute;
    top: 1px;
    width: 30px;
    cursor: pointer;
    z-index: 1;
    margin-left: -60px;
}



:deep(.dp__main .dp__input_icon),
:deep(.dp__main .dp__clear_icon) {
    display: none;
}

:deep(.dp__main .dp__input) {
    padding: 12px;
    font-size: 18px;
}

:deep(.dp__main .dp-webins-calendar) {
   /* padding: 12px;
    font-size: 18px;*/
}

:deep(.dp__main .dp-custom-menu) {
    /*position: fixed;*/

}

/*
:deep(.dp__main .dp__outer_menu_wrap.dp--menu-wrapper) {
    top:50px !important
}*/

.webins-error-field,
.webins-error-field:focus
{
    border-color: #ff2c2c;
    color: #ff2c2c;
}

.webins-input-info {
    float: right;
    margin-left: 5px;
    margin-top:-10px;
    width:30px;
    pointer-events: all;
}
</style>
